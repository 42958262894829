import { Component, useContext, useState } from "react";
import { ProductSingleProductView } from "./ProductSingleProductView";
import { FiRefreshCcw } from "react-icons/fi";
import { BsStar } from "react-icons/bs";
import {
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  Nav,
  Offcanvas,
  Card,
  Form,
  Stack,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CartContext from "./CartContext";
import IncDecCounter from "./IncDecCounterControl";
import { FaHome } from "react-icons/fa";

export default class FavList extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      show: true,
      showSingleView: false,
      ProdSingleView: "",
      showSku: false,
      productView: "list",
    };
  }

  handleProductView = (event) => {
    this.setState({ productView: event.target.value });
    localStorage.setItem("productView", event.target.value);
  };

  forceUpdate = () => {
    this.setState({ value: 0 });
    return () => this.setState({ value: this.state.value + 1 }); // update the state to force render
  };

  OpenSingleView = (prod) => {
    this.setState({ ProdSingleView: prod }, () => {
      this.setState({ showSingleView: true });
    });
  };
  editModalClose = () => {
    this.setState({ showSingleView: false }, () => {
      this.context.forceUpdateCartView();
    });
  };

  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.context.myCart.filter(
      (item) => item.prod.mainCategoryId === maincategory.id
    ).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };

  ValidateProductInstockAvailablity = () => {
    var wishlist = [...this.context.wishListProductIds];
    var newwishlist = [...this.context.wishListProductIds];
    wishlist.map((p, i) => {
      var prd = this.context.prodsAll.filter((f) => f.id == p.prodId);
      if (prd.length == 1) {
        //ok
        console.log("prod in-stock" + prd[0].name);
      } else {
        //TODO:does not delete more than one record
        this.context.DeleteProductOnWishList(p.prodId);
        //newwishlist = newwishlist.filter((productitem) => p.prodId != productitem.prodId)
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    var productView = localStorage.getItem("productView");
    this.setState({ productView: productView });
    //this.ValidateProductInstockAvailablity();
    this.context.UpdateOrderStatus("");

    var showSku = localStorage.getItem("showSku");
    if (showSku == "true") this.setState({ showSku: true });
    else if (showSku == "false") {
      this.setState({ showSku: false });
    }
  }
  getWishListItems = () => {
    // this.context.wishListProductIds;
  };

  handleMenuOffCanvasClose = () => {
    this.setState({ show: false });
    this.props.onCloseWishList();
  };
  handleMenuOffCanvasShow = (e) => {
    this.setState({ show: true });
  };

  getProduct = (prodId) => {
    var prod = this.context.prodsAll.filter((p) => p.id == prodId);
    return prod;
  };
  //   addToCartSubmit = (prod) => {
  //     this.context.addToMyCart({
  //       prod: prod,
  //       qty: 1,
  //     });
  //   };

  addToCartSubmit = (prod) => {
    if (this.context.modeOfSale == "TableOrder") {
      this.props.AddRestaurantTableOrderItem({
        prod: prod,
        qty: 1,
      });
      return;
    } else {
      this.context.addToPOSCart(this.context.selectedPOS, {
        prod: prod,
        qty: 1,
      });
    }
    this.context.forceUpdateCartView();
  };
  handleInputChange = (e, wishListProductId) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "seqNo":
        wishListProductId.seqNo = value;
        this.context.UpdateWishList(wishListProductId);
        this.forceUpdate();
        break;
    }
  };

  render() {
    return (
      <>
        {this.state.showSingleView == true ? (
          <ProductSingleProductView
            show={this.state.showSingleView}
            onHide={this.editModalClose}
            prod={this.state.ProdSingleView}
          />
        ) : (
          ""
        )}
        <div
          className="text-center  w-100 p-1 m-0"
          style={{
            backgroundColor: this.context.searchBorderColor,
            color: "black",
          }}
        >
          <table className="w-100 p-0 m-0">
            <tr className="">
              <td width="20%" className="text-start">
                <h5>
                  <BsStar></BsStar>
                  &nbsp;&nbsp;<b>Favorite List</b>
                </h5>
              </td>
              <td width="20%">
                <Button
                  className=" border-0 CornersRounded"
                  onClick={(e) =>
                    this.context.SetHideFav(!this.context.hideFav)
                  }
                >
                  &nbsp;&nbsp;
                  {this.context.hideFav == true ? (
                    <>Show Fav</>
                  ) : (
                    <>
                      <FaHome size={20}></FaHome>&nbsp; Home
                    </>
                  )}
                  &nbsp;&nbsp;
                </Button>
              </td>
              <td width="20%">
                <Stack direction="horizontal">
                  <Form.Check
                    className=""
                    // type="Check"
                    checked={this.state.showSku}
                    // label="SkuList"
                    onChange={(e) => {
                      this.setState({ showSku: !this.state.showSku });
                      localStorage.setItem("showSku", !this.state.showSku);
                    }}
                  ></Form.Check>
                  &nbsp;SkuList
                </Stack>
              </td>
              <td width="20%" className="text-end">
                <Stack direction="horizontal">
                  <Form.Select
                    className=""
                    type="text"
                    value={this.state.productView}
                    name="productView"
                    label={""}
                    onChange={this.handleProductView}
                  >
                    <option value="grid">Grid View</option>
                    <option value="list">List View</option>
                  </Form.Select>
                </Stack>
              </td>

              <td width="20%" className="text-center">
                <FiRefreshCcw
                  size={"18px"}
                  style={{ fontFamily: "Tahoma", color: "yellow" }}
                  onClick={(e) => {
                    this.context.SortWishList();
                    this.forceUpdate();
                  }}
                ></FiRefreshCcw>
              </td>
            </tr>
          </table>
        </div>
        <div
          className="fixedTableHightOrderQuque"
          style={{
            minHeight: window.innerHeight - 100 + "px",
            maxHeight: window.innerHeight - 100 + "px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {this.state.showSku == true ? (
            <>
              <div>
                {this.context.wishListProductIds.map((wishListProductId) => (
                  <>
                    {this.getProduct(wishListProductId.prodId).length > 0 ? (
                      <>
                        {this.getProduct(wishListProductId.prodId)[0].sku !=
                        undefined ? (
                          <>
                            {this.getProduct(wishListProductId.prodId)[0]
                              .productVariantType == 2 ? (
                              <>
                                <Button
                                  className=""
                                  style={{
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                  }}
                                  onClick={(e) =>
                                    this.OpenSingleView(
                                      this.getProduct(
                                        wishListProductId.prodId
                                      )[0]
                                    )
                                  }
                                >
                                  {
                                    this.getProduct(wishListProductId.prodId)[0]
                                      .sku
                                  }
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  className="m-1"
                                  type="submit"
                                  size="lg"
                                  onClick={(e) =>
                                    this.addToCartSubmit(
                                      this.getProduct(
                                        wishListProductId.prodId
                                      )[0]
                                    )
                                  }
                                >
                                  {
                                    this.getProduct(wishListProductId.prodId)[0]
                                      .sku
                                  }
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.productView == "grid" ? (
            <>
              <div className="row justify-content-around py-2">
                {this.context.wishListProductIds.map((wishListProductId) => (
                  <>
                    {this.getProduct(wishListProductId.prodId).length > 0 ? (
                      <>
                        <Card style={{ width: "3.5cm" }} className="border-0">
                          {this.getProduct(wishListProductId.prodId)[0]
                            .imgFileName == undefined ||
                          this.getProduct(wishListProductId.prodId)[0]
                            .imgFileName == null ||
                          this.getProduct(wishListProductId.prodId)[0]
                            .imgFileName == "" ? (
                            <>
                              <td></td>
                            </>
                          ) : (
                            <>
                              <Card.Img
                                variant="top"
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  this.getProduct(wishListProductId.prodId)[0]
                                    .imgFileName +
                                  this.context.store.storageSasToken
                                }
                                className="card-img-top border-0 text-center align-top align-content-start "
                                alt="..."
                                // onClick={(e) => this.OpenSingleView(e)}
                                onClick={(e) =>
                                  this.OpenSingleView(
                                    this.getProduct(wishListProductId.prodId)[0]
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                              />
                            </>
                          )}
                          <Card.Body className="p-2 m-0 ">
                            <Card.Text className="text-center p-0 m-0">
                              {
                                this.getProduct(wishListProductId.prodId)[0]
                                  .name
                              }
                              <br />
                              <h5>
                                <b>
                                  {Math.round(
                                    Number(
                                      this.getProduct(
                                        wishListProductId.prodId
                                      )[0].mrp -
                                        (this.getProduct(
                                          wishListProductId.prodId
                                        )[0].mrp *
                                          this.getProduct(
                                            wishListProductId.prodId
                                          )[0].discount) /
                                          100
                                    ),
                                    0
                                  ).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </b>
                                {this.getProduct(wishListProductId.prodId)[0]
                                  .discount > 0 ? (
                                  <>
                                    &nbsp;&nbsp;&nbsp;
                                    <br></br>
                                    <small style={{ fontSize: "10px" }}>
                                      {
                                        this.getProduct(
                                          wishListProductId.prodId
                                        )[0].discount
                                      }
                                      % OFF
                                    </small>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </h5>
                              {this.getProduct(wishListProductId.prodId)[0]
                                .productVariantType == 2 ? (
                                <>
                                  <Button
                                    className="align-content-center w-100 border buttonRounded buttonwithshadow"
                                    style={{
                                      backgroundColor: "cyan",
                                      color: "black",
                                    }}
                                    onClick={(e) =>
                                      this.OpenSingleView(
                                        this.getProduct(
                                          wishListProductId.prodId
                                        )[0]
                                      )
                                    }
                                  >
                                    View
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="align-content-center w-100 CornersRounded"
                                    type="submit"
                                    style={{
                                      backgroundColor: "cyan",
                                      color: "black",
                                    }}
                                    onClick={(e) =>
                                      this.addToCartSubmit(
                                        this.getProduct(
                                          wishListProductId.prodId
                                        )[0]
                                      )
                                    }
                                  >
                                    &nbsp;+ADD&nbsp;
                                  </Button>
                                </>
                              )}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </div>
            </>
          ) : (
            <>
              <Table border className=" px-5" responsive="md">
                <thead>
                  <tr className="bg-light text-start text-secondary">
                    <th></th>
                    <th width="45%"></th>
                    <th width="20%" className="text-center">
                      S.Price
                    </th>
                    <th width="10%"></th>
                    <th width="5%"></th>
                    <th width="10%">Seq.No</th>
                  </tr>
                </thead>
                <tbody className="align-middle">
                  {this.context.wishListProductIds.map((wishListProductId) => (
                    <>
                      {this.getProduct(wishListProductId.prodId).length > 0 ? (
                        <>
                          <tr>
                            {this.getProduct(wishListProductId.prodId)[0]
                              .imgFileName == undefined ||
                            this.getProduct(wishListProductId.prodId)[0]
                              .imgFileName == null ||
                            this.getProduct(wishListProductId.prodId)[0]
                              .imgFileName == "" ? (
                              <>
                                <td></td>
                              </>
                            ) : (
                              <>
                                <td width="10%">
                                  <img
                                    src={
                                      this.context.store.storageBlobUrl +
                                      this.context.store
                                        .storageBlobContainerName +
                                      "/images/" +
                                      this.getProduct(
                                        wishListProductId.prodId
                                      )[0].imgFileName +
                                      this.context.store.storageSasToken
                                    }
                                    className="cardprodimg-on-cartview mx-auto"
                                    alt="..."
                                    style={{
                                      cursor: "pointer",
                                      width: ".5cm",
                                    }}
                                  />
                                </td>
                              </>
                            )}

                            <td width="45%">
                              {
                                this.getProduct(wishListProductId.prodId)[0]
                                  .name
                              }
                              <br />
                              <span className="text-primary">
                                {" "}
                                SKU :{" "}
                                {
                                  this.getProduct(wishListProductId.prodId)[0]
                                    .sku
                                }{" "}
                              </span>
                            </td>
                            <td width="20%" className="text-center">
                              <h5>
                                <b>
                                  {Math.round(
                                    Number(
                                      this.getProduct(
                                        wishListProductId.prodId
                                      )[0].mrp -
                                        (this.getProduct(
                                          wishListProductId.prodId
                                        )[0].mrp *
                                          this.getProduct(
                                            wishListProductId.prodId
                                          )[0].discount) /
                                          100
                                    ),
                                    0
                                  ).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </b>
                                {this.getProduct(wishListProductId.prodId)[0]
                                  .discount > 0 ? (
                                  <>
                                    &nbsp;&nbsp;&nbsp;
                                    <br></br>
                                    <small style={{ fontSize: "10px" }}>
                                      {
                                        this.getProduct(
                                          wishListProductId.prodId
                                        )[0].discount
                                      }
                                      % OFF
                                    </small>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </h5>
                            </td>
                            <td width="10%" className="text-center">
                              {this.getProduct(wishListProductId.prodId)[0]
                                .productVariantType == 2 ? (
                                <>
                                  <Button
                                    className="align-content-center w-100 border buttonRounded buttonwithshadow"
                                    style={{
                                      backgroundColor:
                                        this.context.theme
                                          .mainNavBarBackgroundColor,
                                      color:
                                        this.context.theme.mainNavBarTextColor,
                                    }}
                                    onClick={(e) =>
                                      this.OpenSingleView(
                                        this.getProduct(
                                          wishListProductId.prodId
                                        )[0]
                                      )
                                    }
                                  >
                                    View
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="align-content-center w-100 CornersRounded"
                                    type="submit"
                                    style={{
                                      // backgroundColor: "#6EC531"
                                      backgroundColor: "cyan",
                                      color: "black",
                                    }}
                                    onClick={(e) =>
                                      this.addToCartSubmit(
                                        this.getProduct(
                                          wishListProductId.prodId
                                        )[0]
                                      )
                                    }
                                  >
                                    &nbsp;+ADD&nbsp;
                                  </Button>
                                </>
                              )}
                            </td>
                            <td width="5%">
                              <OverlayTrigger
                                overlay={<Tooltip>Delete!</Tooltip>}
                                popperConfig={{
                                  modifiers: {
                                    preventOverflow: {
                                      enabled: false,
                                    },
                                  },
                                }}
                              >
                                <Button
                                  variant="outline-danger"
                                  onClick={(e) => {
                                    if (
                                      window.confirm("Do you want to Delete?")
                                    ) {
                                      this.context.DeleteProductOnWishList(
                                        wishListProductId.prodId
                                      );
                                    }
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                    />
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            </td>
                            <td width="10%">
                              <Form.Control
                                type="text"
                                name="seqNo"
                                value={wishListProductId.seqNo}
                                required
                                placeholder=""
                                autoComplete="new-password"
                                className="text-center w-100"
                                onChange={(e) =>
                                  this.handleInputChange(e, wishListProductId)
                                }
                              />
                            </td>
                          </tr>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
        {/* </Offcanvas.Body>
        </Offcanvas> */}
      </>
    );
  }
}
