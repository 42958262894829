import React, { Component, useRef, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import HoverControlledSplitButton from "./ProductsList";
import RefreshBearerToken from "./RefreshBearerToken";
import Swal from "sweetalert2";
import { FaFileInvoice } from "react-icons/fa";

import {
  ControlledMenu,
  useHover,
  useMenuState,
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import {
  BsFillHeartFill,
  BsFillHouseDoorFill,
  BsPerson,
  BsCart2,
  BsHeart,
  BsBag,
} from "react-icons/bs";

import { FiRefreshCcw } from "react-icons/fi";
import { LoadSpinner } from "./LoadSpinner";
import UserLoginNew from "./UserLoginNew";

import { useNavigate } from "react-router-dom";
import {
  Badge,
  OverlayTrigger,
  Popover,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import CartContext from "./CartContext";
import SearchView from "./SearchView";
import Select from "react-select";
import { getMainCategories } from "../Helper/ConsumerHelper";
import "../CSS/textanimation.css";
import { Navbar, Nav, Button, Form, FormControl } from "react-bootstrap";
import { render } from "@testing-library/react";
import CartOffCanvas from "./CartOffCanvas";

export default class Navigation extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      loginRefreshCounter: 1,
      storeId: "",
      loadSpinnerOpen: false,

      ShowUserLoginNew: false,
    };
    this.unload.bind(this);
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  refreshBearerToken = (newval) => {
    this.setState({ loginRefreshCounter: newval + 1 });
    console.log("refresh called");
    this.context.RefreshBearerToken(
      this.context.userData.emailId,
      this.context.userData.password,
      this.context.userData
    );
  };
  refreshBearerTokenWithMobileNo = (newval) => {
    this.setState({ loginRefreshCounter: newval + 1 });
    console.log("refresh called");
    this.context.refreshBearerTokenWithMobileNo(
      this.context.userData.mobileNo,
      this.context.userData
    );
  };

  GetMyStoreDetails = () => {
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert(
            "This Application is not active yet. Please contact your Vendor!"
          );
        } else {
          let expirydate = new Date(data.storeEnd);
          const diffTime = Math.abs(new Date() - expirydate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (new Date(data.storeEnd) > new Date() == false) {
            alert(
              "Information: Your Store has expired. Kindly contact us for Renewal of your subscription!"
            );
            return;
          } else if (diffDays < 30) {
            alert(
              "Renewal Remainder! Your Store will expire on " +
                this.context.GetInDateFormat(new Date(data.storeEnd)) +
                ". Kindly contact us for Renewal of your subscription!"
            );
          }

          this.context.setStoreId(data.id);
          if (this.state.storeId != "") return;
          this.setState({ storeId: data.id }, () => {
            this.context.GetStore();
            this.context.GetStoreSettings();
            this.context.GetPosUserRoles();
            //this.context.GetMainCarousel(1); //1-desktop 2-Mobile
            this.context.GetOrderStatusTypes();
            this.context.GetAllProducts();
            this.context.getMainCategories();
            this.context.getSubCategories();
            this.context.getBrands();
            //this.context.GetMainMenus();
            //this.context.GetProductGroups();
            this.context.getShippingDetails();
            this.context.getTaxDetails();
            this.context.GetRestaturantTables();
            this.context.GetRestaurantTableOrdereStatus();
            //this.context.SetModeOfSale("PointOfSale");
            // if (this.context.userData.loginStatus === "inactive")
            //   this.setState({ ShowUserLoginNew: true });
          });
        }
      })
      .catch((error) => {
        alert("You're entering Offline-Mode!");
        this.context.LoadOffLineData();
      });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.unload);

    const platform = window.navigator.platform;
    if (
      platform.includes("Mac") ||
      platform.includes("Sun") ||
      platform.includes("iPhone")
    ) {
      this.fakeRequest().then(() => {
        if (this.state.storeId == "") {
          this.GetMyStoreDetails();
        }
      });
    } else {
      if (this.context.storeId == "") {
        this.GetMyStoreDetails();
      }
    }
  }
  componentWillUnmount() {
    // window.removeEventListener("beforeunload", this.unload);
  }
  unload(e) {}

  componentDidUpdate() {}

  HideUserLoginNew = () => {
    //this.setState({ ShowUserLoginNew: false });
    this.context.ShowUserLoginNew(false);
  };

  SignOut = () => {
    this.setState({ ShowUserLoginNew: true });
    this.context.clearUserData();
    this.context.ShowUserLoginNew(true);
  };

  render() {
    if (this.context.userData == "" || this.context.userData == undefined)
      return <></>;
    if (this.context.userData.loginStatus == "active") {
      //this.context.GetTotalSalesToday(); Dont delete
      if (this.context.prevPage != "") {
        var prevPage = this.context.prevPage;
        this.context.storePreviousPage("");
        return <Navigate to={prevPage} />;
      }
    }

    // if (window.location.pathname == "/CompleteOrderViewTable") return <></>;
    if (this.context.hideMainNavBar == true) return;

    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        {this.context.userData.loginStatus === "active" ? (
          <RefreshBearerToken></RefreshBearerToken>
        ) : (
          ""
        )}

        {this.context.showUserLoginNew === true &&
        this.context.isOfflineMode == false ? (
          <UserLoginNew
            show={this.context.showUserLoginNew}
            onHide={this.HideUserLoginNew}
          />
        ) : null}
        <MainNavigationBar SignOut={this.SignOut}></MainNavigationBar>
      </>
    );
  }
}

class MainNavigationBar extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      showOverlay: false,
    };
  }

  handleOnMouseEnter = () => {
    //setShowOverlay(true);
    this.setState({ showOverlay: true });
  };
  handleOnMouseLeave = () => {
    //setShowOverlay(false);
    this.setState({ showOverlay: false });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      redirect: "/",
    });
  };

  componentDidMount() {
    console.log(this.context.userdata);
  }
  componentDidUpdate() {
    // this.context.refreshbearertoken(
    //   this.context.userdata.emailId,
    //   this.context.userdata.password
    // );
  }

  SignOut = () => {
    this.props.SignOut();
  };

  OnLogin = () => {
    this.context.ShowUserLoginNew(true);
  };
  OnRefresh = () => {
    this.context.GetAllProducts();
    Swal.fire({
      title: "refreshing..please wait",
      // confirmButtonText: "yes",
      timer: 5000,
    }).then((result) => {
      // this.setState({ searchsku: "" });
    });
  };

  render() {
    return (
      <>
        {window.innerWidth >= 1100 ? (
          <>
            <Navbar
              className="position-sticky fixed-top m-0 p-1 bg-white  mainnavbarborder"
              style={{ fontFamily: "Tahoma", color: "navy" }}
            >
              <Navbar.Collapse
                className="justify-content-start m-0 p-0 border-0"
                style={{ width: "20%" }}
              >
                <Nav className="border mx-2 px-2 CornersRounded">
                  <a
                    href="https://elasticcart.com"
                    className="logohover m-0 p-0   "
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        // backgroundColor: "#1BDBDB",
                        color: "#142B6B",
                        fontFamily: "fantasy",
                        fontSize: "24px",
                      }}
                    >
                      <img
                        className=""
                        src={"ec.png"}
                        style={{
                          cursor: "pointer",
                          width: "1cm",
                          backgroundColor: "transparant",
                        }}
                      ></img>
                      &nbsp;ElasticCART.COM&nbsp;
                    </span>
                  </a>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse
                className="justify-content-start m-0 p-0 border-0"
                style={{ width: "10%" }}
              >
                <Nav className="border-0 m-0 p-0">
                  <Nav.Link
                    as={Link}
                    to="/home"
                    className="logohover m-0 p-0 border-0"
                  >
                    {this.context.isOfflineMode == true ? (
                      <>
                        <h1>Home</h1>
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            this.context.storeSettings.storeLogoFileName +
                            this.context.store.storageSasToken
                          }
                          className={"logohover m-0 p-0 rounded-pill "}
                          style={{
                            cursor: "pointer",
                            color: this.context.theme.mainNavBarTextColor,
                            width: "3cm",
                            backgroundColor: "transparant",
                            filter:
                              "invert(" + this.context.theme.invertLogo + "%)",
                          }}
                        />
                      </>
                    )}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse
                className="justify-content-center m-0 p-0 border CornersRounded bg-light"
                style={{ width: "40%" }}
              >
                {this.context.userData.posRoleId == 1 ||
                this.context.userData.posRoleId == 2 ||
                this.context.userData.posRoleId == null ||
                this.context.userData.posRoleId == undefined ? (
                  <>
                    <Nav className="mx-5 p-1  text-center border-0">
                      <Nav.Link
                        as={Link}
                        to="/home"
                        className="logohover m-0 p-0 border-0 "
                        onClick={(e) =>
                          this.context.SetModeOfSale("PointOfSale")
                        }
                        style={{ fontFamily: "Tahoma", color: "navy" }}
                      >
                        {this.context.modeOfSale == "PointOfSale" ? (
                          <>
                            <h4
                              className=" border-0 border-dark p-1 px-1 rounded-pill"
                              style={{
                                fontFamily: "Tahoma",
                                backgroundColor: this.context.themeBorderColor,
                                color: "white",
                              }}
                            >
                              {window.innerWidth < 1200 ? (
                                <b>POS </b>
                              ) : (
                                <b>&nbsp;Point of Sale - POS&nbsp;</b>
                              )}
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4
                              className=" border-0  p-1 px-1 rounded-pill"
                              style={{
                                fontFamily: "Tahoma",
                                //backgroundColor: this.context.themeBorderColor,
                                // color: "white",
                              }}
                            >
                              {window.innerWidth < 1200 ? (
                                <b>POS </b>
                              ) : (
                                <b>&nbsp;Point of Sale - POS&nbsp;</b>
                              )}
                            </h4>
                          </>
                        )}
                      </Nav.Link>
                    </Nav>
                  </>
                ) : (
                  <></>
                )}
                {this.context.store.businessTypeId == 3 &&
                this.context.isOfflineMode == false &&
                this.context.userData.posRoleId == 1 ? (
                  <>
                    <Nav>
                      <h1>|</h1>
                    </Nav>
                  </>
                ) : (
                  <></>
                )}

                {this.context.store.businessTypeId == 3 &&
                this.context.isOfflineMode == false &&
                (this.context.userData.posRoleId == 1 ||
                  this.context.userData.posRoleId == 3) ? (
                  <>
                    <Nav className="mx-5 p-1   text-center border-0">
                      <Nav.Link
                        as={Link}
                        to="/TableOrders"
                        onClick={(e) => this.context.SetBaseModeForTable()}
                        className="logohover m-0 p-0 border-0 "
                        style={{ fontFamily: "Tahoma", color: "navy" }}
                      >
                        {this.context.modeOfSale == "TableOrder" ? (
                          <>
                            <h4
                              // className="text-light border border-dark p-1 px-4 rounded-pill"
                              // style={{
                              //   fontFamily: "Tahoma",
                              //   backgroundColor: "navy",
                              //   color: "white",
                              // }}
                              className=" border border-dark p-1 px-1 rounded-pill"
                              style={{
                                fontFamily: "Tahoma",
                                backgroundColor: this.context.themeBorderColor,
                                color: "white",
                              }}
                            >
                              {window.innerWidth < 1200 ? (
                                <b>Tables </b>
                              ) : (
                                <b>&nbsp;Table Orders&nbsp;</b>
                              )}
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4>
                              {window.innerWidth < 1200 ? (
                                <b>Tables </b>
                              ) : (
                                <b>&nbsp;Table Orders&nbsp;</b>
                              )}
                            </h4>
                          </>
                        )}
                      </Nav.Link>
                    </Nav>
                  </>
                ) : (
                  ""
                )}
              </Navbar.Collapse>
              <Navbar.Collapse
                className="justify-content-end border-0"
                style={{ width: "30%" }}
              >
                <Nav>
                  {this.context.isOfflineMode == true ? (
                    <>
                      <h5 className="my-2 p-2 CornersRounded bg-dark text-warning text-center">
                        OFFLINE-MODE
                        <br />
                        <small className="text-white">
                          &nbsp;Unsync Orders ={" "}
                          {this.context.offlineOrdersCount} &nbsp;
                        </small>
                      </h5>
                    </>
                  ) : (
                    <>
                      {this.context.offlineOrdersCount > 0 ? (
                        <>
                          <h5 className="my-2 py-1 bg-dark text-warning text-center">
                            &nbsp;Unsync Orders ={" "}
                            {this.context.offlineOrdersCount}
                            &nbsp;
                            <br />
                            <Button
                              onClick={(e) => this.context.SyncOrdersStart()}
                            >
                              Sync Now
                            </Button>
                          </h5>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Nav>
                {/* <span
                  className="border CornersRounded bg-secondary text-white text-center"
                  style={{ fontSize: "16px" }}
                >
                  &nbsp; Today Sale &nbsp;
                  {Number(this.context.todayTotalSale).toFixed(0)}&nbsp;
                </span> */}
                &nbsp;
                <Nav>
                  <Nav.Link
                    as={Link}
                    disabled={this.context.isOfflineMode}
                    className="py-1 navBarLink posbutton text-white"
                    to={"https://" + this.context.store.storeAdminDomainName}
                    target="_blank"
                  >
                    Admin
                  </Nav.Link>
                </Nav>
                <Nav className="mx-2">
                  <Button
                    // as={Link}
                    className="py-3 text-primary bg-light border-0"
                    disabled={this.context.isOfflineMode}
                    // to="/Home"
                    onClick={this.OnRefresh}
                  >
                    <FiRefreshCcw
                      size={"22px"}
                      style={{ fontFamily: "Tahoma", color: "navy" }}
                    ></FiRefreshCcw>
                  </Button>

                  <Nav.Link
                    as={Link}
                    className="py-3 "
                    to=""
                    hidden={this.context.userData.hideSignupLink}
                    onClick={this.OnLogin}
                  >
                    <BsPerson
                      size={"22px"}
                      style={{ fontFamily: "Tahoma", color: "navy" }}
                    ></BsPerson>
                  </Nav.Link>
                  <OverlayTrigger
                    show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                    key="bottom"
                    placement="bottom"
                    rootClose="false"
                    overlay={
                      <Popover
                        id={`popover-positioned-bottom`}
                        onMouseEnter={this.handleOnMouseEnter}
                        onMouseLeave={this.handleOnMouseLeave}
                        className=""
                      >
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          <UserMenu SignOut={this.SignOut}></UserMenu>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      className="rounded-circle "
                      variant=""
                      hidden={!this.context.userData.hideSignupLink}
                      onMouseEnter={this.handleOnMouseEnter}
                      onMouseLeave={this.handleOnMouseLeave}
                    >
                      <BsPerson
                        size={"22px"}
                        style={{ fontFamily: "Tahoma", color: "navy" }}
                      ></BsPerson>
                    </Button>
                  </OverlayTrigger>
                  <Nav.Link
                    as={Link}
                    action
                    className="py-3 border CornersRounded"
                    variant="dark"
                    disabled={
                      this.context.isOfflineMode ||
                      this.context.storeSettings.hideMyOrdersinPos == true
                    }
                    to="/UserOrders"
                  >
                    <FaFileInvoice size="22" />
                    {/* My Invoices <br /> */}
                    <b>
                      Last Bill:&nbsp;
                      {Number(this.context.lastBillAmount).toFixed(0)}
                    </b>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </>
        ) : (
          <>
            <Navbar
              className="position-sticky fixed-top m-0 p-1 bg-white text-dark mainnavbarborder"
              style={{ fontFamily: "Tahoma", color: "navy" }}
            >
              <Navbar.Collapse
                className="justify-content-start m-0 p-0 border-0"
                style={{ width: "30%" }}
              >
                <Nav className="border-0 m-0 p-0">
                  <Nav.Link
                    as={Link}
                    to="/home"
                    className="logohover m-0 p-0 border-0"
                  >
                    <img
                      src={
                        this.context.store.storageBlobUrl +
                        this.context.store.storageBlobContainerName +
                        "/images/" +
                        this.context.storeSettings.storeLogoFileName +
                        this.context.store.storageSasToken
                      }
                      //className="logohover invertimage m-0 p-0"
                      className={"logohover m-0 p-0 rounded-pill "}
                      style={{
                        cursor: "pointer",
                        color: this.context.theme.mainNavBarTextColor,
                        width: "3cm",
                        backgroundColor: "transparant",
                        filter:
                          "invert(" + this.context.theme.invertLogo + "%)",
                      }}
                    />
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse
                className="justify-content-center m-0 p-0 border-0"
                style={{ width: "50%" }}
              >
                {this.context.userData.posRoleId == 1 ||
                this.context.userData.posRoleId == 2 ||
                this.context.userData.posRoleId == null ||
                this.context.userData.posRoleId == undefined ? (
                  <>
                    <Nav
                      className="mx-2 p-1  text-center border-0"
                      style={{ fontFamily: "Tahoma", color: "navy" }}
                    >
                      <Nav.Link
                        as={Link}
                        to="/home"
                        className="logohover m-0 p-0 border-0 "
                        style={{ fontFamily: "Tahoma", color: "navy" }}
                        onClick={(e) =>
                          this.context.SetModeOfSale("PointOfSale")
                        }
                      >
                        {this.context.modeOfSale == "PointOfSale" ? (
                          <>
                            <h4 className="my-0">
                              <b>
                                <u>POS</u>
                              </b>
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4 className="my-0">
                              <b>POS</b>
                            </h4>
                          </>
                        )}
                      </Nav.Link>
                    </Nav>
                  </>
                ) : (
                  <></>
                )}
                {this.context.store.businessTypeId == 3 &&
                this.context.userData.posRoleId == 1 ? (
                  <>
                    <Nav>
                      <h1>|</h1>
                    </Nav>
                  </>
                ) : (
                  <></>
                )}
                {this.context.store.businessTypeId == 3 &&
                (this.context.userData.posRoleId == 1 ||
                  this.context.userData.posRoleId == 3) ? (
                  <>
                    <Nav className="mx-2 p-1  text-dark text-start border-0">
                      <Nav.Link
                        as={Link}
                        to="/TableOrders"
                        className="logohover m-0 p-0 border-0 "
                        style={{ fontFamily: "Tahoma", color: "navy" }}
                      >
                        {this.context.modeOfSale == "TableOrder" ? (
                          <>
                            <h4 className="my-0">
                              <b>
                                <u>TABLE</u>
                              </b>
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4 className="my-0">
                              <b>TABLE</b>
                            </h4>
                          </>
                        )}
                      </Nav.Link>
                    </Nav>
                  </>
                ) : (
                  ""
                )}
              </Navbar.Collapse>
              <Navbar.Collapse
                className="justify-content-end border-0"
                style={{ width: "20%" }}
              >
                <Nav className="mx-2">
                  <Nav.Link
                    as={Link}
                    className="py-3 "
                    to=""
                    hidden={this.context.userData.hideSignupLink}
                    onClick={this.OnLogin}
                  >
                    <BsPerson
                      size={"22px"}
                      style={{ fontFamily: "Tahoma", color: "navy" }}
                    ></BsPerson>
                  </Nav.Link>
                  <OverlayTrigger
                    show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                    key="bottom"
                    placement="bottom"
                    rootClose="false"
                    overlay={
                      <Popover
                        id={`popover-positioned-bottom`}
                        onMouseEnter={this.handleOnMouseEnter}
                        onMouseLeave={this.handleOnMouseLeave}
                        className=""
                      >
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          <UserMenu SignOut={this.SignOut}></UserMenu>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      className=""
                      variant=""
                      hidden={!this.context.userData.hideSignupLink}
                      onMouseEnter={this.handleOnMouseEnter}
                      onMouseLeave={this.handleOnMouseLeave}
                    >
                      <BsPerson
                        size={"22px"}
                        style={{ fontFamily: "Tahoma", color: "navy" }}
                      ></BsPerson>
                    </Button>
                  </OverlayTrigger>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </>
        )}
      </>
    );
  }
}

class UserMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Row className="bg-whie text-center">
          <label className="bg-whie text-center">
            <h4>Hi {this.context.userData.firstName}</h4>
          </label>
        </Row>
        <ListGroup variant="flush" className="fw-bold bg-dark">
          {/* <ListGroup.Item
            as={Link}
            action
            disabled={this.context.isOfflineMode}
            variant="dark"
            to="/UserOrders"
          >
            My Invoices
          </ListGroup.Item> */}
          <ListGroup.Item
            as={Link}
            action
            disabled={this.context.isOfflineMode}
            variant="dark"
            to="/LicensePage"
          >
            Apply License
          </ListGroup.Item>

          <ListGroup.Item variant="dark" className="bg-dark"></ListGroup.Item>

          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/home"
            onClick={(e) => {
              this.context.clearUserData();
              this.props.SignOut();
            }}
          >
            Sign Out
          </ListGroup.Item>
        </ListGroup>
      </>
    );
  }
}
